<template>
  <div
    :style="style"
    class="overflow-hidden"
    v-bind="attr"
  >
    <div
      class="tw-faq !-mr-8 flex flex-col flex-nowrap justify-center overflow-auto"
    >
      <WebAccordion
        v-for="(item, index) of faq"
        :id="index"
        :key="index"
        :open="opened === index"
        :title="item.title"
        :content="item.desc"
        :options="element.options"
        :page-options="pageOptions"
        :style="element.style"
        @opened="(indis) => opened = indis"
        @closed="() => opened = -1"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { type PropType, computed, ref} from 'vue';
import type { Element as ElementType, PageOptions } from '@shared/types/model';
import type { ElementOptions } from '@shared/types/options';
import WebAccordion from './components/accordion.vue';

const props = defineProps({
  style: { type: Object, default: () => ({}) },
  attr: { type: Object as PropType<ElementType<'faq'>['attr']>, default: () => ({}) },
  element: { type: Object as PropType<ElementType<'faq'>>, default: () => ({}) },
  config: { type: Object as PropType<ElementOptions['faq']>, default: () => ({}) },
  isEditorMode: { type: Boolean, default: false },
  pageOptions: { type: Object as PropType<PageOptions>, default: () => ({}) }
});
const opened = ref(-1);
const values = computed(() => JSON.parse(props.element.values || '{}'));
const faq = computed(() =>
  values.value.faq.slice(0, props.config.count?.current || props.config.count?.max)
);

</script>

<style lang="postcss">
  .tw-faq {
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    &-block {
      @apply snap-start;
    }
  }
  
  .slide-up {
    &-enter-active,
    &-leave-active {
      transition: all 0.15s ease-out;
    }
    &-enter-from {
      opacity: 0;
      margin-top: -24px;
    }

    &-leave-to {
      opacity: 0;
      margin-top: -24px;
    }
  }

  .tablet {
    .tw-faq-block {
      scroll-snap-type: none;
      scroll-snap-align: unset;

      &:nth-of-type(2n + 1) {
        scroll-snap-stop: always;
        scroll-snap-align: start;
      }
    }
  }

  .desktop {
    .tw-faq-block {
      scroll-snap-type: none;
      scroll-snap-align: unset;

      &:nth-of-type(3n + 1) {
        scroll-snap-stop: always;
        scroll-snap-align: start;
      }
    }
  }

  @media screen and (min-width: 768px) {
    .tw-faq-block {
      scroll-snap-type: none;
      scroll-snap-align: unset;

      &:nth-of-type(2n + 1) {
        scroll-snap-stop: always;
        scroll-snap-align: start;
      }
    }
  }

  @media screen and (min-width: 1024px) {
    .tw-faq-block {
      &:nth-of-type(2n + 1) {
        scroll-snap-type: none;
        scroll-snap-align: unset;
      }

      &:nth-of-type(3n + 1) {
        scroll-snap-stop: always;
        scroll-snap-align: start;
      }
    }
  }
</style>
